import React, {useEffect} from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';
import qCoreApi from 'api';
import deviceStore from 'stores/DeviceStore';
import {Table, withTableSorting, withTableActions, Select} from '@gravity-ui/uikit';
import { useTranslation } from 'hooks/useTranslation';
import {Label} from '@gravity-ui/uikit';
import { useNavigate, useParams } from 'react-router-dom';
import {DropdownMenu, Tabs, Loader} from '@gravity-ui/uikit';

const extract_numbers = (str) => {
    const res = str.match(/\d+/g);
    if (res === null) {
        return 0;
    }
    return parseInt(res.join(''));
}

const DevicePage = observer(() => {
    const device_id = useParams().id;
    const { t } = useTranslation('devices');
    const navigate = useNavigate();

    const [device_status, setDeviceStatus] = React.useState({});
    const [device_logs, setDeviceLogs] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState('Services');
    const [loading, setLoading] = React.useState(true);
    
    useEffect(() => {
        setDeviceStatus({});
        setDeviceLogs([]);
        setLoading(true);
        
        qCoreApi.get_device_status(device_id, (data) => {
            setDeviceStatus(data);
            console.log(data);
            setLoading(false);
        }, (error) => {
            console.log(error);
        });
        if (deviceStore.devices.length === 0) {
            qCoreApi.get_devices((data) => {
                deviceStore.setDevices(data);
            }, (error) => {
                console.log(error);
            });
        }
        qCoreApi.get_device_logs(device_id, (data) => {
            setDeviceLogs(data);
            console.log(data);
        }, (error) => {
            console.log(error);
        });
        
    }, [device_id]);

    console.log(deviceStore.devices.map((device) => ({value: device.id, content: device.name})))
    console.log(device_id)
    return (
        <div
            style = {{
                
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                alignSelf: 'center',
               
            }}
        >
           <div
            style = {{
                position: 'sticky',
                zIndex: 1000,
                top:41,
                backgroundColor: 'white',
            }}
           >
            <Select
                label={t('device_select_device')}
                options={deviceStore.devices.map((device) => ({value: device.id, content: device.name})).sort(
                    (a, b) => extract_numbers(a.content) < extract_numbers(b.content) ? -1 : 1
                )}
                defaultValue={[device_id]}
                onUpdate={(value) => navigate(`/devices/${value[0]}`)}
            />
            <Tabs
                activeTab = {activeTab}
                onSelectTab = {setActiveTab}
                
                items = {
                    [{
                        "id" : "Services",
                        'title' : t('device_services_tab')
                    },
                    {
                        "id" : "artifacts",
                        'title' : t('device_artifacts_tab')
                    },
                    {
                        'id' : "Packages",
                        'title' : t('device_packages_tab')
                    },
                    {
                        "id" : "Logs",
                        'title' : t('device_logs_tab')
                    }
                ]
                }
            >
                
            </Tabs>
            </div>
            <div>
            {loading?<div
                style = {{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px'
                }}
            >
                <Loader size="l"></Loader>
                </div> :<>
            {
                activeTab === 'Services' && (
                    <div>
                        
                        <Table
                            data = {device_status.serviceStatus?device_status.serviceStatus:[]}
                            style={{
                                overflow: 'auto'
                            }}
                            columns = {[
                                {
                                    'id' : 'name',
                                    'name' : t('device_services_name')
                                },
                                {
                                    'id' : "version",
                                    'name' : t('device_services_version')
                                },
                                {
                                    'id' : 'status',
                                    'name' : t('device_services_status'),
                                    'template' : (row) => {
                                        return <Label
                                            theme={row.status === 'DEPLOYED' ? 'success' : 'normal'}
                                        >{row.status}</Label>
                                    }
                                }
                            ]}
                        />
                    </div>
                )
            }
            {
                activeTab === 'artifacts' && (
                    <div>
                        <Table
                            data = {device_status.artifactStatus}
                            style={{
                                overflow: 'auto'
                            }}
                            columns = {[
                                {
                                    'id' : 'urn',
                                    'name' : t('device_artifacts_name')
                                },
                                {
                                    'id' : "sha256",
                                    'name' : t('device_artifacts_sha256'),
                                    'template' : (row) => {
                                        return row.sha256?.substring(0, 10);
                                    }
                                },
                                {
                                    'id' : 'status',
                                    'name' : t('device_artifacts_status'),
                                    'template' : (row) => {
                                        return <Label
                                            theme={row.status === 'LOADED' ? 'success' : 'danger'}
                                        >{row.status}</Label>
                                    }
                                }
                            ]}
                        />
                    </div>
                )
            }
            {
                activeTab === 'Packages' && (
                    <div>
                        <Table
                            data = {device_status.packageStatus}
                            style={{
                                overflow: 'auto'
                            }}
                            columns = {[
                                {
                                    'id' : 'name',
                                    'name' : t('device_package_name')
                                },
                                {
                                    'id' : 'status',
                                    'name' : t('device_packages_status'),
                                    'template' : (row) => {
                                        return <Label
                                            theme={row.status === 'LOADED' ? 'success' : 'danger'}
                                        >{row.status}</Label>
                                    }
                                }
                            ]}
                        />
                    </div>
                )
            }
            {
                activeTab === 'Logs' && (
                    <div>
                        <Table
                            data = {device_logs}
                            style={{
                                overflow: 'auto'
                            }}
                            columns = {[
                                {
                                    'id' : 'log_level',
                                    'name' : t('device_logs_level'),
                                    'template' : (row) => {
                                        return <Label
                                            theme={row.log_level === 'INFO' ? 'info' : 'danger'}
                                        >{row.log_level}</Label>
                                    }
                                },
                                {
                                    'id' : 'timestamp',
                                    'name' : t('device_logs_timestamp'),
                                    'template' : (row) => {
                                        return new Date(row.timestamp).toLocaleString();
                                    }
                                },
                                {
                                    'id' : 'event',
                                    'name' : t('device_logs_event'),
                                    'template' : (row) => {
                                        return <Label
                                            theme='info'
                                        >{row.event}</Label>
                                    }
                                },
                                {
                                    'id' : "sub_event",
                                    'name' : t('device_logs_sub_event'),
                                    'template' : (row) => {
                                        return <Label
                                            theme='utility'
                                        >{row.sub_event}</Label>
                                    }
                                },
                                {
                                    'id' : 'message',
                                    'name' : t('device_logs_message')
                                }
                            ]}
                        />
                    </div>
                )
            }</>}
            </div>
        </div>
    );
}
);


export default DevicePage;