import React, {useState, useEffect} from 'react';
import {AsideHeader, Footer, FooterItem} from '@gravity-ui/navigation';
import {Button, Menu, User} from '@gravity-ui/uikit';
import { useAuth } from 'react-oidc-context';

import logo from 'assets/logo192.png'

import {Molecule} from '@gravity-ui/icons';
import {Tv} from '@gravity-ui/icons';
import {Filmstrip} from '@gravity-ui/icons';
import {Globe} from '@gravity-ui/icons';
import {Person} from '@gravity-ui/icons';
import {BranchesRightArrowRight} from '@gravity-ui/icons';
import {CirclePlusFill} from '@gravity-ui/icons';
import {Hammer} from '@gravity-ui/icons';
import {Rocket} from '@gravity-ui/icons';
import { languages } from 'constants/languages';
import { useNavigate , useLocation} from 'react-router-dom';

import { useTranslation } from 'hooks/useTranslation';  


import userStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';

import QHeader from 'ui/Header';

const allMenuItems = [
    {
        id: "Services",
        title: "services_title",
        icon: Molecule,
        iconSize: 18,
        tooltipText: "services_tooltip",
        type: "regular",
        href: "/services"
    },
    {
        id: "Devices",
        title: "devices_title",
        icon: Tv,
        iconSize: 18,
        pinned: true,
        type: "regular",
        tooltipText: "devices_tooltip",
        href: "/devices"
    },
    {
        id : "Campaigns",
        title : "campaigns_title",
        icon : Filmstrip,
        iconSize : 18,

        type: "regular",
        tooltipText: "campaigns_tooltip",
        href: "/campaigns"
    },
    {
        id : "Policies",
        title : "policies_title",
        icon : BranchesRightArrowRight,
        iconSize : 18,
        type: "regular",
        tooltipText: "policies_tooltip",
        href: "/policies"
    },
    {
        id: "Device Maintenance",
        title: "device_maintenance_title",
        icon: Hammer,
        iconSize: 18,
        pinned: true,
        type: "regular",
        tooltipText: "device_maintenance_tooltip",
        href: "/devices/mainenance"
    },
    {
        id: "Device Registration",
        title: "device_registration_title",
        icon: CirclePlusFill,
        iconSize: 18,
        pinned: true,
        type: "regular",
        tooltipText: "device_registration_tooltip",
        href: "/devices/register"
    },
    {
        id: "Create Campaign",
        title: "create_campaign_title",
        icon: Rocket,
        iconSize: 18,
        pinned: true,
        type: "regular",
        tooltipText: "create_campaign_tooltip",
        href: "/campaigns/create"
    }
]


const UserFooter = observer(({collapsed, compact}) => {
    const auth = useAuth();
    if (!auth.isAuthenticated) {
        return (
           <></>
        );
    }
    return (
        <><User
        name={collapsed?"":userStore.user.name}
        description={collapsed?"":userStore.user.email}
        avatar={{imgUrl:userStore.user.picture}}
        compact={compact}
        size={"s"}
        style={{
        display: "flex",
        marginLeft: "14px",
        marginBottom: collapsed?"16px":"14px",
        }}
    ></User>
    <FooterItem
                item ={{
                    type: "divider"
                }}
            />
    </>)
})
            

const QAsideHeader = observer(({renderContent}) => {
    const auth = useAuth();
    const [collapsed, setCollapsed] = useState(false);
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    const {t, i18n}=useTranslation("aside_header");
    const [menuItems, setMenuItems] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        const updatedMenuItems = allMenuItems.map((item) => ({
            ...item,
            title: t(item.title),
            current: location.pathname === item.href,
            tooltipText: t(item.tooltipText),
            onItemClick: (item, collapsed, event) => {
                console.log(item);
                navigate(item.href);
            }
        })).filter((item) => userStore.user.info?.services.includes(item.id));
        setMenuItems(updatedMenuItems);
        
    }, [i18n.language, t, location, userStore.user.info?.services]);

    useEffect(() => {

        if (auth.isAuthenticated) {
            console.log(auth.user.profile);
            userStore.setUser({
                ...userStore.user,
                picture : auth.user.profile.picture, 
                name : auth.user.profile.preferred_username,
                email : auth.user.profile.email
            });
            console.log(userStore.user);
        }
    }
    , [auth.isAuthenticated]);
    
    const login = () => {
        auth.signinRedirect();
    };
    return <AsideHeader title={<div>test</div>}
        headerDecoration={true}
        menuMoreTitle={t("menu_more_title")}
        logo = {{
        
        iconSrc: logo,
        text: "Quantum Core"
        }}
        menuItems={menuItems}
        
        renderFooter={(compact)=>
        <>
            <UserFooter collapsed={collapsed} compact={compact}/>
            
            <FooterItem
                item={{
                id: "Language",
                title: t("language_title"),
                
                icon: Globe,
                iconSize: 18,
                onItemClick: (item, collapsed, event) => {
                    setShowLanguageMenu(!showLanguageMenu);
                },
                type: "regular",
                }}
                popupVisible={showLanguageMenu}
                onClosePopup={() => setShowLanguageMenu(false)}
                popupPlacement={["right-end"]}
                renderPopupContent={() =>
                <div
                    style= {{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
                    }}
                >
                
                    {
                    languages.map((lang) => (
                        <Button
                        key={lang}
                        view={i18n.language===lang?"action":"flat"}
                        size="m"
                        width='max'
                        onClick={() => {
                            i18n.changeLanguage(lang);
                            setShowLanguageMenu(false);
                        }}
                        >
                        {lang}
                        </Button>
                    ))
                    }
                    </div>
                }
                
            />
            <FooterItem
                item={{
                id: "User",
                title: t("user_title"),
                icon: Person,
                iconSize: 18,
                type: "regular",
                tooltipText: t("user_tooltip"),
                }}
            
                
            />
        
        </>
        
        }

        compact={collapsed}
        onChangeCompact={(collapsed) => {
        setCollapsed(collapsed);
        }}

        renderContent={() => 
            <div
                style ={{
                    overflow: "auto",
                    height: "100%",
                }}
            >
                <QHeader/>
                <div
                    style = {{
                        padding: "20px",
                        display: "flex",
                    }}
                >
                    <div
            style = {{
                maxHeight: 'calc(100vh - 150px)',
                width : "100%",
                zIndex:1
            }}
        >
                {renderContent()}
                </div>
                </div>
            </div>}
           

        />
       
})

export default QAsideHeader;