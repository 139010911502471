import React from "react";

import { MainPageCard } from "components/MainPageView"

const CampaignPrediction = () => {
    return (
        <MainPageCard>
                <h1>Prediction</h1>

        </MainPageCard>
    )
}

export default CampaignPrediction