import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import {Label, Button, Icon, Alert} from '@gravity-ui/uikit';

import {MainPageCard} from 'components/MainPageView';
import InfoField from 'components/InfoField';
import {Stepper, Step, StepLabel, Typography} from '@mui/material';
import {Xmark} from '@gravity-ui/icons';


const StepperSx = {
    "& .MuiStepConnector-root": {
      left: "calc(-50% + 40px)",
      right: "calc(50% + 40px)"
    },
    "& .MuiStepConnector-line": {
      marginTop: "22px" // To position the line lower
    }
  };

const DeviceMainInfo = ({ name, step, uuid, status, onClose }) => {
    const { t } = useTranslation("device_registration");
    const steps = [
        {
            id: 'register',
            label: t("register_step")
        },
        {
            id: 'install',
            label: t('install_step')
        },
        {
            id : 'autotest',
            label: t('autotest_step')
        },
        {
            id : 'manual_test',
            label: t('manual_test_step')
        }
    ]

    return (
       <MainPageCard>
            <div
                style = {{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                width: '100%',
                justifyContent: 'space-between',
                }}
            >
                <div
                    style ={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    }}
                >
                    <h3 style = {{margin: 0, padding:0}}>Shark</h3>
                    {
                        (status === "active") ? 
                            <Label theme="success">{t("device_status_connected")}</Label> : 
                            <Label theme="warning">{t("device_status_unconnected")}</Label>
                    }
                </div>
            <Button onClick={() => onClose()}>
                
                <Icon data={Xmark} size={16} style={{marginRight: '10px'}} />
                {t("device_close_registration")}
            </Button>
            </div>
            <Alert
                theme="warning" message={t("device_activation_warning")}
                style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                }}
            />
            <div style = {{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                width: '100%',
                marginBottom: '50px'
            }}>
                <InfoField label = {t("device_main_info_name")} value={name} />
                <InfoField label = {t("device_main_info_uuid")} value={uuid} />
            </div>
            <Stepper 
                style={{width: '100%'}}
                alternativeLabel 
                activeStep={step}
                sx={StepperSx}
            >
                {steps.map((step) => (
                    <Step key={step.id}>
                        <Typography align="center"
                            variant='subtitle2'
                        >{step.label}</Typography>
                        <StepLabel icon = {''}></StepLabel>
                    </Step>
                ))}
            </Stepper>
            </MainPageCard>
      );
}

export default DeviceMainInfo;