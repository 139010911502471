import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@gravity-ui/uikit';
import {QRCodePrinter} from './QRPrinter';
import DownPageOverlay, {DownPageRightContent, DownPageTwoSidesContent} from 'components/DownPageOverlay';

const PrintLabelButton = ({ row, text}) => {
    console.log(row)
    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });
    return <><Button onClick={reactToPrintFn} view="outlined-action" >{text}</Button>
            <QRCodePrinter ref = {contentRef} name={row?.name} uuid={row?.id} />
    </>
}

const ActionButton = ({ row,text}) => {
    return <Button> {text} </Button>
}

const ReportButton = ({ row,text}) => {
    return <Button> {text}</Button>
}

const DownPageSteps = ({hasPrevious, onPrevious, onNext, leftContent, rightContent}) => {
    return (
        <DownPageOverlay>
            {hasPrevious ?
                <DownPageTwoSidesContent>
                      <Button
                    view="action"
                    style={{ minWidth: '170px', justifySelf: 'flex-begin' }}
                    size='l'
                    width="auto"
                    onClick={() => onPrevious()}
                >
                    {leftContent}
                </Button> 
                <Button
                    view="action"
                    style={{ minWidth: '170px', justifySelf: 'flex-end' }}
                    size='l'
                    width="auto"
                    onClick={() => onNext()}
                >
                    {rightContent}
                </Button>
                </DownPageTwoSidesContent>
              : <DownPageRightContent>
                <Button
                    view="action"
                    style={{ 
                        minWidth: '170px', 
                        justifySelf: 'flex-end', 
                        gap: '10px' }}
                    size='l'
                    width="auto"
                    onClick={() => onNext()}
                >
                    {rightContent}
                </Button>
            </DownPageRightContent>
            }
        </DownPageOverlay>
    )
}


export {PrintLabelButton, ActionButton, ReportButton, DownPageSteps}