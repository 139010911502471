import React from "react";

import { MainPageCard } from "components/MainPageView"


const CampaignScenario = () => {
    return (
        <MainPageCard>
            <div>
                <h1>Scenario</h1>
            </div>
        </MainPageCard>
    )
}

export default CampaignScenario