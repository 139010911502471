import React, { useEffect, useRef, useState, forwardRef} from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal } from '@gravity-ui/uikit';

import MainPageView from 'components/MainPageView';
import {DownPageSteps} from './utils';
import DeviceTable from './DeviceTable';
import DeviceMainInfo from './MainInfo';
import DeviceQRPrinting from './QRPrinter';

import SelectDeviceType from './SelectType';
import DeviceServiceTable from './ServiceTable';
import DeviceAutoTestingTable from './AutoTesting';
import ManualDeviceTesting from './ManualTesting';
import DeviceReport from './Report';
import qCoreApi from 'api';
import {useToaster} from '@gravity-ui/uikit';

const DeviceRegistrationPage = () => {
    console.log(process.env.REACT_APP_BASE_URL)
    const { t } = useTranslation("device_registration");

    const {add} = useToaster();

    const [step, setStep] = React.useState(0);
    const [manual_step, setManualStep] = React.useState(0);
    const [pin_number, setPinNumber] = React.useState('');

    const [device, setDevice] = React.useState({
        name : 'photon1',
        uuid : 'ae1f8d5e-0d6b-4f2c-8b8b-7b4d0b1f9c41',
        status : 'active'
    });

    const [devices, setDevices] = React.useState([]);
    
    const [tests, setTests] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [manualTests, setManualTests] = React.useState([]);
    const [device_types, setDeviceTypes] = React.useState([
        {
            value: 'Almaty 2024',
            content: 'Almaty 2024'
        }
    ]
    );

    const [selectedDeviceType, setSelected] = React.useState([]);
    const [showReport, setShowReport] = React.useState(false);

    const [install, setInstall] = React.useState(false);


    const navigate = useNavigate();
    const params = useParams();

    const onClose = () => {
        setStep(0);
        setManualStep(0);
        setShowReport(false);
        setSelected([]);
        setInstall(false);
    }


    useEffect(() => {
        qCoreApi.get_devices_registration_info((data) => {
            setDevices(data.devices.map((x) => {
                return {
                    uuid: x["id"],
                    ...x
                }
            }))
        }, (error) => {
            console.log(error);
            add({
                'title' : "Can't get devices",
                'isClosable' : true,
                
            })

        })
    }, []);


    if (step === 0) {
        return <DeviceTable
            devices={devices}
            onNextStep={async (pin_number) => {
                console.log(pin_number)
                await qCoreApi.register_device(pin_number, (data) => {
                    console.log(data, data.device, 11111)
                    setDevice(data.device);
                    console.log(data.device)
                    setStep(1);
                    setPinNumber(pin_number);
                }
                , (error) => {
                    console.log(error);
                    add({
                        'title' : "Can't register device",
                        'isClosable' : true,
                        'theme' : 'danger',
                        'content' : 'Please try again later',
                        'autoHiding': 5000,
                        'action' : ['Retry']
                    })
                })
                
            }}
        />
    } else if (step === 1) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <DeviceQRPrinting
                name={device.name}
                uuid={device.uuid}
            />
            <DownPageSteps
                hasPrevious={false}
                rightContent={t("next_step")}
                onNext={() => setStep(2)}
            />
        </MainPageView>
    } else if (step === 2) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <SelectDeviceType 
                onSelect={setSelected}
                onInstall={async () => {
                    await qCoreApi.verify(pin_number, (data) => {
                        //setDevice(data.device);
                        // console.log(data.device)
                        setStep(3);
                    }
                    , (error) => {
                        console.log(error);
                        add({
                            'title' : "Can't register device",
                            'isClosable' : true,
                            'theme' : 'danger',
                            'content' : 'Please try again later',
                            'autoHiding': 5000,
                            'action' : ['Retry']
                        })
                    })
                    
                }}
                selected={selectedDeviceType}
                options = {device_types}
            />
            {
                install ? < DeviceServiceTable services={services}/> : null
            }
             
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => setStep(1)}
                rightContent={t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => setStep(3)}
            />
        </MainPageView>
    } else if (step  === 3) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <DeviceAutoTestingTable
                tests = {tests}
            />
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => setStep(2)}
                rightContent={t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => {setStep(4); setManualStep(0)}}
            />
        </MainPageView>
    } else if (step === 4) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <ManualDeviceTesting 
                step={manual_step}
            />
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => {
                    if (manual_step > 0) {
                        setManualStep(manual_step-1)
                    } else {
                        setStep(3)
                        setManualStep(0)
                    }
                }}
                rightContent={manual_step===3?t("finish_step"):t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => {
                    if (manual_step < 3) {
                        setManualStep(manual_step+1)
                    } else {
                        setShowReport(true)
                    }
                }}
            />
          <Modal
            open={showReport}
            onClose={() => setShowReport(false)}
          >
            <DeviceReport
                device_name={device.name}
                uuid={device.uuid}
                registraion_date={"2024-09-01T00:00:00"}
                installation_date={"2024-09-01T00:00:00"}
                autotests={tests}
                manual_tests={tests}
                onClose={onClose}
            />
          </Modal>
        </MainPageView>
    } 
};


export default DeviceRegistrationPage;