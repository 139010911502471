import {api_endpoint} from 'constants/api';
import { oidc_config } from 'constants/auth'; 

const client_id = oidc_config.client_id;

const getAuthHeader = (content_type = "application/json") => {
    const oidcStorage = localStorage.getItem(`oidc.user:${oidc_config.authority}:${client_id}`);
    const  access_token= JSON.parse(oidcStorage).access_token;
    const myHeaders = new Headers();
    myHeaders.append('qcore-jwt-token', access_token);
    if (content_type) {
        myHeaders.append('Content-Type', content_type);
    }
    return myHeaders;
}

const TEST_ENDPOINT='http://192.168.23.215:8004/api/v1';


class QCoreApi {
    constructor() {
    }

    async get_services(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/services`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_policies(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/policies`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_user_info(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}user`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_policies_targets(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/policies/device/targets`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_service_deployment_status(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/services/deployment/status`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_devices(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/devices`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_device_status(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/devices/${device_id}/status`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

   
        

    async get_device_logs(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/devices/${device_id}/logs`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data.logs);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async apply_policy(service_id, policy_id, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader(),
           
        };
        const res = await fetch(`${api_endpoint}/services/${service_id}/apply/policy/${policy_id}`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async add_device(name, config, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader(),
            body: JSON.stringify({
                name: name,
                config: config
            })
        };
        const res = await fetch(`${api_endpoint}/devices`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async update_device(device_id, name, config, onSuccess, onError) {
        const requestOptions = {
            method: 'PUT',
            headers: getAuthHeader(),
            body: JSON.stringify({
                name: name,
                config: config
            })
        };
        const res = await fetch(`${api_endpoint}/devices/${device_id}`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

// Device Registration API

    async get_devices_registration_info(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/devices/registration/info`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async register_device(pin_number, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${TEST_ENDPOINT}/code/${pin_number}/status`, requestOptions);
        console.log(res)
        if (res.ok) {
            // console.log(res)
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            // console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async verify(pin_number, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader()
        };
        const res = await fetch(`${TEST_ENDPOINT}/code/${pin_number}/verify`, requestOptions);
        console.log(res)
        if (res.ok) {
            // console.log(res)
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            // console.log(data);
            onError(JSON.stringify(data));
        }
    }
}

const qCoreApi = new QCoreApi();

export default qCoreApi;