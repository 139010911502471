import React, {useEffect} from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';
import qCoreApi from 'api';
import serviceStore from 'stores/ServiceStore';
import {Table, withTableSelection, withTableActions} from '@gravity-ui/uikit';
import { useTranslation } from 'hooks/useTranslation';
import {Label, Select} from '@gravity-ui/uikit';
import {DropdownMenu} from '@gravity-ui/uikit';
import { CopyBlock, a11yDark,
    a11yLight,
    anOldHope,
    androidstudio,
    arta,
    atomOneDark,
    atomOneLight,
    codepen,
    dracula,
    far,
    github,
    googlecode,
    hopscotch,
    hybrid,
    irBlack,
    monoBlue,
    monokaiSublime,
    monokai,
    nord,
    noctisViola,
    obsidian,
    ocean,
    paraisoDark,
    paraisoLight,
    pojoaque,
    purebasic,
    railscast,
    rainbow,
    shadesOfPurple,
    solarizedDark,
    solarizedLight,
    sunburst,
    tomorrowNightBlue,
    tomorrowNightBright,
    tomorrowNightEighties,
    tomorrowNight,
    tomorrow,
    vs2015,
    xt256,
    zenburn } from "react-code-blocks";

const MyTable =  (withTableSelection(Table));

const themes = [
    { value: a11yDark, label: 'a11yDark' },
    { value: a11yLight, label: 'a11yLight' },
    { value: anOldHope, label: 'anOldHope' },
    { value: androidstudio, label: 'androidstudio' },
    { value: arta, label: 'arta' },
    { value: atomOneDark, label: 'atomOneDark' },
    { value: atomOneLight, label: 'atomOneLight' },
    { value: codepen, label: 'codepen' },
    { value: dracula, label: 'dracula' },
    { value: far, label: 'far' },
    { value: github, label: 'github' },
    { value: googlecode, label: 'googlecode' },
    { value: hopscotch, label: 'hopscotch' },
    { value: hybrid, label: 'hybrid' },
    { value: irBlack, label: 'irBlack' },
    { value: monoBlue, label: 'monoBlue' },
    { value: monokaiSublime, label: 'monokaiSublime' },
    { value: monokai, label: 'monokai' },
    { value: nord, label: 'nord' },
    { value: noctisViola, label: 'noctisViola' },
    { value: obsidian, label: 'obsidian' },
    { value: ocean, label: 'ocean' },
    { value: paraisoDark, label: 'paraisoDark' },
    { value: paraisoLight, label: 'paraisoLight' },
    { value: pojoaque, label: 'pojoaque' },
    { value: purebasic, label: 'purebasic' },
    { value: railscast, label: 'railscast' },
    { value: rainbow, label: 'rainbow' },
    { value: shadesOfPurple, label: 'shadesOfPurple' },
    { value: solarizedDark, label: 'solarizedDark' },
    { value: solarizedLight, label: 'solarizedLight' },
    { value: sunburst, label: 'sunburst' },
    { value: tomorrowNightBlue, label: 'tomorrowNightBlue' },
    { value: tomorrowNightBright, label: 'tomorrowNightBright' },
    { value: tomorrowNightEighties, label: 'tomorrowNightEighties' },
    { value: tomorrowNight, label: 'tomorrowNight' },
    { value: tomorrow, label: 'tomorrow' },
    { value: vs2015, label: 'vs2015' },
    { value: xt256, label: 'xt256' },
    { value: zenburn, label: 'zenburn' },
];

const PolicyPage = observer(() => {
    const [selectedId, setSelectedId] = React.useState('0');
    const [theme, setTheme] = React.useState({value: themes[0].label, content: themes[0].label});

    const { oidcUser } = useAuth();
    const { t } = useTranslation('policies');
    useEffect(() => {
        qCoreApi.get_policies((data) => {
            console.log(data);
            serviceStore.setPolicies(data.policies);
        }, (error) => {
            console.log(error);
        });
    }, []);
    return (
        <div
            style = {{
           
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
            }}
        >
            <div>
            <h2>{t('policy_table_title')}</h2>
            <MyTable
                data={serviceStore.policies}
                selectedIds={[selectedId]}
                onSelectionChange={(data) => {console.log(data);setSelectedId(data[1])}}
                columns={[
                    {
                        'id' :'name',
                        'name' : t('policy_table_name'),
                        'meta'  : {
                            'width' : '200px'
                        }
                    },
                    {
                        'id' :'description',
                        'name' : t('policy_table_description'),
                    },
                    {
                        'id' :'type',
                        'name' : t('policy_table_type'),
                        'meta' : {
                            'width' : '200px'
                        }
                    }
                ]}
            />
            </div>
            <div 
                style = {{
                    minWidth: '500px',
                    maxWidth: '800px',
                    overflow: 'auto',
                }}
            >
            <h2>{t('policy_code_title')}</h2>
           {/*<Select 
              options={themes.map((theme) => ({value: theme.label, content: theme.label}))}
              onUpdate={theme => {console.log(theme);setTheme({value: theme[0], content: theme[0]})}}
                            />*/}
            <CopyBlock
            
      text={serviceStore.policies[selectedId]?.code}
      language={'python'}
      showLineNumbers={false}
      startingLineNumber={1}
      theme={github}
    />
    </div>
        </div>
    );
}
);

export default PolicyPage;

