import React, {useEffect} from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';
import qCoreApi from 'api';
import deviceStore from 'stores/DeviceStore';
import {Table, withTableSorting, withTableActions} from '@gravity-ui/uikit';
import { useTranslation } from 'hooks/useTranslation';
import {Label} from '@gravity-ui/uikit';
import { useNavigate } from 'react-router-dom';
import {DropdownMenu} from '@gravity-ui/uikit';

const MyTable =  (withTableSorting(Table));

const extract_numbers = (str) => {
    const res = str.match(/\d+/g);
    if (res === null) {
        return 0;
    }
    return parseInt(res.join(''));
}

const DevicePage = observer(() => {
    const [selectedIds, setSelectedIds] = React.useState([]);
    const { oidcUser } = useAuth();
    const { t } = useTranslation('devices');
    const navigate = useNavigate();
    useEffect(() => {
        qCoreApi.get_devices((data) => {
            deviceStore.setDevices(data);
        }, (error) => {
            console.log(error);
        });
        qCoreApi.get_service_deployment_status((data) => {
            deviceStore.setDeviceDeploymentStatus(data);
        }, (error) => {
            console.log(error);
        });
    }, []);
    console.log(deviceStore.devices);
    return (
        <div
            style = {{
                maxWidth: '800px',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                alignSelf: 'center',
            }}
        >
            <h2>{t('device_table_title')}</h2>
            <MyTable
                data={deviceStore.devices}
                selectedIds={selectedIds}
                onRowClick={(row) => navigate(`/devices/${row.id}`)}
                onSelectionChange={setSelectedIds}
                columns={[
                    {
                        'id' :'name',
                        'name' : t('device_table_name'),
                        'meta'  : {
                            defaultSortOrder: 'desc',
                            sort: (a, b) => extract_numbers(a.name) < extract_numbers(b.name) ? -1 : 1
                        }
                    }, 
                    {
                        'id' :'status',
                        'name' : t('device_table_status'),
                        'template' : (row) => {
                            return <Label
                                theme={row.status === 'active' ? 'success' : 'danger'}
                            >{row.status}</Label>
                        }
                    },
                    {
                        'id' :'City',
                        'label': t('device_table_city'),
                        'template' : (row) => {
                            return <Label
                                theme={row.config?.city === 'Almaty' ? 'success' : 'danger'}
                            >{row.config?.city}</Label>
                        }
                    },
                    {
                        'id' : 'type',
                        'name' : t('device_table_type'),
                        'template' : (row) => {
                            return <Label
                                theme={row.config?.type === 'Shark' ? 'utility' : 'danger'}
                            >{row.config?.type}</Label>
                        }
                    },
                    {
                        'id' : 'services',
                        'name' : t('device_table_services'),
                        
                        
                    }
                   
                ]}
            />
        </div>
    );
}
);

export default DevicePage;