import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Table, Label } from '@gravity-ui/uikit';
import { MainPageCard } from 'components/MainPageView';


const DeviceServiceTable = ({ services }) => {
    const { t } = useTranslation("device_registration");
    return (
        <MainPageCard>
            <Table
                data={services}
                columns={[
                    { 
                        id: 'name', 
                        name: t("device_service_name"), 
                        width: 1000 
                    },
                    { 
                        id: 'version', 
                        name: t("device_service_version"), 
                        width: 1000 
                    },
                    { 
                        id: 'status', 
                        name: t("device_service_status"),
                        width: 1000, 
                        template: (row) => {
                        return <Label theme={row.status === 'installed' ? 'success' : 'warning'}>
                            {row.status==='installed'?t("device_service_installed"):t("device_service_installing")}
                            </Label>
                    } },
                ]}
            />
        </MainPageCard>
    )
}

export default DeviceServiceTable;