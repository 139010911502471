import React, {useState, useEffect} from 'react';
import QAsideHeader from 'ui/AsideHeader';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'hooks/useTranslation';
import qCoreApi from 'api';
import ServicesPage from 'pages/services/ServicesPage';
import DevicesPage from 'pages/devices/DevicesPage';
import DevicePage from 'pages/devices/DevicePage';
import PolicyPage from 'pages/policies/PolicyPage';
import CreateCampaignPage from 'pages/campaigns/create/';
import userStore from 'stores/UserStore';
import DeviceRegistrationPage from 'pages/devices/registration';

function App() {
  const auth = useAuth();
  const {t, i18n}=useTranslation();
  
  useEffect(() => {
    
    if (auth.isAuthenticated) {
      qCoreApi.get_user_info(
        (data) => {
          userStore.setUser(data["user"]);
          i18n.changeLanguage(data["user"]["preferences"]["language"]);
        }, 
        (error) => {console.log(error)}
      );
    } else {
      console.log("User is not authenticated");
    }
    console.log(auth.isAuthenticated);
    if (auth.isAuthenticated.error) {
      console.log(auth.isAuthenticated.error);
    }
  }, [auth.isAuthenticated]);

  return  auth.isAuthenticated ? (
    <Router basename={process.env.REACT_APP_BASE_URL}>
      <QAsideHeader
        renderContent={() => 
            <Routes>
              <Route path="/" element={<>Quantum System</>} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/devices" element={<DevicesPage />} />
              <Route path="/devices/register" element={<DeviceRegistrationPage />} />
              <Route path="/devices/:id" element={<DevicePage />} />
              <Route path="/policies" element={<PolicyPage />} />
              <Route path="campaigns/create" element={<CreateCampaignPage />} />
            </Routes>
        }
      />
    </Router>
    
  ) : (<Router>
    <QAsideHeader
      renderContent={() => 
          <Routes>
            <Route path="/" element={<>Quantum System</>} />
          </Routes>
        
      }
    
    />
  </Router>);
}

export default App;
