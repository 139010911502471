import React from 'react';
import {Button, Icon} from '@gravity-ui/uikit';

import { useTranslation } from 'hooks/useTranslation';  
import { useNavigate , useLocation} from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import {ArrowRightFromSquare} from '@gravity-ui/icons';
import { Breadcrumbs } from '@gravity-ui/uikit';




const QHeader = () => {

    const auth = useAuth();
    const location = useLocation();
    const { t } = useTranslation("aside_header");

    return (
        <div 
            style = {{
                position: 'sticky',
                top: '0px',
                left: '0px',
                zIndex: 1000,
                background: 'var(--g-color-base-background)',
            }}
        >
            <div
                style = {
                    {
                        display: "flex",
                        borderBottom: "1px solid var(--g-color-line-generic)",
                        height: "40px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 20px",
                        
                    }
                }
            >
            <Breadcrumbs
                items={location.pathname.split('/').filter((item) => item !== "").map((item) => {
                    
                    return {
                        text: item,
                        isCurrent : true
                    }
                })
                }
            />
            <Button
                view="normal"
                size="m"
        
                onClick={() => {
                    if(auth.isAuthenticated){
                        auth.signoutRedirect();
                    }else{
                        auth.signinRedirect();
                    }
                }}
            >
                {auth.isAuthenticated?<Icon data={ArrowRightFromSquare} size={18}/>:null}
                {auth.isAuthenticated?t("logout_title"):t("login_title")}
            </Button>
            </div>
        </div>
       
    );
    }

export default QHeader;