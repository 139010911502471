import React from "react"
import { Card } from "@gravity-ui/uikit"

const MainPageView = ({children}) => {
    return <div style={{
        width: "calc(100% - 100px)",
        display: 'flex',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '20px',
        flexDirection: 'column',
        gap: '30px'
    }}>
        {children}
    </div>
}

const MainPageCard = ({children}) => {
    return <Card 
        size='l'    
        style={{
           paddingTop: '25px',
           paddingBottom: '25px',
           paddingLeft: '25px',
           paddingRight: '25px',
           display : 'flex',
           flexDirection: 'column',
           alignItems: 'baseline',
           gap: '10px'
       }}
    >{
         children
    }
    </Card> 
}

export default MainPageView
export {MainPageCard}