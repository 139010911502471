import React, {useState} from "react"

import MainPageView, { MainPageCard } from "components/MainPageView"
import CampaignMainInfo from "./MainInfo";
import CampaignPrediction from "./Prediction";
import CampaignScenario from "./Scenario";

const CreateCampaignPage = () => {

    const [campaign, setCampaign] = useState({
        name: "SuperPuperName",
        description: "",
        startDate: "2024-10-01",
        endDate: "2024-11-01",
        budget: 10000,
        target: "",
        price: 1000
    });

    return (
        <MainPageView>
            <CampaignMainInfo campaign = {campaign} setCampaign={setCampaign}/>
            <CampaignPrediction campaign={campaign} setCampaign={setCampaign}/>
            <CampaignScenario campaign={campaign} setCampaign={setCampaign}/>
        </MainPageView>
    )
    }

export default CreateCampaignPage