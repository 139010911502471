import { makeAutoObservable, action, observable } from 'mobx';

class UserStore {
    user = {
        name: 'User',
        email: 'test@gmail.com',
        roles: ['admin'],
        services: ['Services',  'Policies','Devices','Campaigns', "Device Maintenance"],
    };

    constructor() {
        makeAutoObservable(this, {
            user: observable,
            setUser: action,
        });
    }

    setUser(user) {
        console.log(user);
        this.user = user;
    }
}

const userStore = new UserStore();
export default userStore;