import React, {useRef} from "react";
import { useTranslation } from 'hooks/useTranslation';
import { Card, Table, Button, Modal, PinInput, Icon } from '@gravity-ui/uikit';
import MainPageView from 'components/MainPageView';
import {DownPageSteps, PrintLabelButton,ActionButton,ReportButton} from './utils';
import { Plus } from '@gravity-ui/icons';
import { formatDateToMinutesFromString } from 'utils/date';


const DeviceTable = ({ devices, onNextStep }) => {
    const [openPinModal, setOpenPinModal] = React.useState(false);
    const {t} = useTranslation("device_registration");

    const [pin_number, setPinNumber] = React.useState([]);

    const DeviceTableCard = ({children}) => {
        return (
            <Card size='l' style={
                {
                    width: '100%',
                    paddingLeft: '50px',
                    paddingRight: '50px'
                }
            }>
                {children}
            </Card>
        )
    }

    return (
        <MainPageView>
            <DeviceTableCard>
                <h3>{t("unactivated_devices")}</h3>
                <Table
                    data={devices.filter((device) => device.status !== "active")}
                    width={"max"}
                    columns={[
                        { 
                            id: 'name', 
                            name: t("device_table_name"), 
                            width: 1000 
                        },
                        { 
                            id: 'created_at', 
                            name: t("device_table_date"), 
                            width: 1000,
                            template: (row) => formatDateToMinutesFromString(row?.created_at)
                        },
                        { 
                            id: 'print_label', 
                            name: t("device_table_label"), 
                            width: 1000, 
                            template: (row) => <PrintLabelButton 
                                                row={row}
                                                text={t("device_print_label")}
                                            /> },
                        { 
                            id: 'action', 
                            name: t("device_table_action"), 
                            width: 1000, 
                            template: () => <ActionButton 
                                                text={t("device_view_label")} 
                                            /> },
                    ]}
                />
            </DeviceTableCard>
            <DeviceTableCard>
                <h3>{t("activated_devices")}</h3>
                <Table
                    data={devices.filter((device) => device.status == "active")}
                    columns={[
                        { 
                            id: 'name', 
                            name: t("device_table_name"), 
                            width: 1000 
                        },
                        { 
                            id: 'created_at', 
                            name: t("device_table_date"), 
                            width: 1000,
                            template: (row) => formatDateToMinutesFromString(row?.created_at)
                        },
                        { 
                            id: 'print_label', 
                            name: t("device_table_label"), 
                            width: 1000, 
                            template: (row) => <PrintLabelButton 
                                                row={row}
                                                text={t("device_print_label")}
                                            /> },
                        { 
                            id: 'report', 
                            name: t("device_table_report"), 
                            width: 1000, 
                            template: () => <ReportButton 
                                                text={t("device_report_label")} 
                                            /> },
                    ]}
                />
            </DeviceTableCard>
            <Modal open={openPinModal} onClose={() => setOpenPinModal(false)}>
                <div style={{
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',

                }}>
                    <h3>
                        {t("device_pin_number_title")}
                    </h3>
                    <div style={{

                        fontSize: '14px',
                        color: '#666666'
                    }}>
                        {t("device_pin_number_description")}    
                    </div>
                    <PinInput value={pin_number} 
                     onUpdate={(value) => {setPinNumber(value);}}
                     length={6} 
                     size='xl' 
                     style={{ marginTop: '20px', marginBottom: '10px' }} />
                    <Button
                        view="action"
                        size='l'
                        style={{ marginTop: '20px' }}
                        onClick={() => { onNextStep(pin_number.join('')); setOpenPinModal(false); }}
                    >
                        {t("device_pin_number_activate")}
                    </Button>
                </div>
            </Modal>
            <DownPageSteps
                hasPrevious={false}
                onNext={() => setOpenPinModal(true)}
                rightContent={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center'
                        }}
                    >
                        <Icon data={Plus} size={16} style={{ marginRight: '10px' }} />
                        {t("activate_step")}
                    </div>
                }
            />

            
        </MainPageView>
    )
}

export default DeviceTable;