import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Table, Label, Card, Button, Alert } from '@gravity-ui/uikit';

import { formatDateToMinutesFromString } from 'utils/date';
import InfoField from 'components/InfoField';

const SystemTable = ({registration_date, installation_date}) => {
    const { t } = useTranslation("device_registration");
    return (
        <ReportCard>
        <Table
            data={[
                {
                    id: 'registration_date',
                    name: t("device_system_registration_name"),
                    value: registration_date,
                    status : "pass"
                },
                {
                    id: 'installation_date',
                    name: t("device_system_installation_name"),
                    value: installation_date,
                    status : "pass"
                }
            ]}
            columns={[
                { 
                    id: 'name', 
                    name: t("device_system_report_name"), 
                    width: 550,
                   
                },
                { 
                    id: 'value', 
                    name: t("device_system_report_date"), 
                    template : (row) => formatDateToMinutesFromString(row?.value),
                    width: 550
                },
                {
                    id: 'status',
                    name: t("device_system_report_status"),
                    width: 150,
                    template : (row) => {
                        return <Label theme={row.status === 'pass' ? 'success' : 'danger'}>
                            {row.status==='pass'?t("device_test_pass"):t("device_test_fail")}
                        </Label>
                    }
                }
            ]}
        />
         </ReportCard>
    )
}


const DeviceReport = ({device_name, 
                      uuid, 
                      registraion_date, 
                      installation_date, 
                      autotests, 
                      manual_tests, 
                      status,
                      onClose}) => {

    const { t } = useTranslation("device_registration");
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                padding:'20px',
                marginBottom:'100px',
                marginTop:'20px',
                maxWidth: '850px',
                maxHeight: 'calc(100vh - 200px)',
                overflow: 'auto',
            }}
        >
            <div
                style = {{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
           <h2>{t("device_report_title")}</h2>
           <div 
            style = {{
                color: '#666666',
            }}
           >
            { formatDateToMinutesFromString(registraion_date)}
           </div>
           </div>
           <div
            style={{
                display:'flex'
            }}
           >
            {status==="success"?<Alert
                theme="success" message={t("device_success_activation_message")}
                />:
                <Alert
                theme="danger" message={t("device_fail_activation_message")}
             />
            }
            </div>
            <div
                style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: '20px'
                }}
            >
                <div
                    style = {{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                    }}
                >
                    <InfoField label = {t("device_main_info_name")} value={device_name} />
                    <InfoField label = {t("device_main_info_uuid")} value={uuid} />
                </div>
               
            </div>
            <div
                style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    marginTop: '20px'
                }}
            >
                <h3>{t("report_system_name")}</h3>
                <SystemTable
                    registration_date={registraion_date}
                    installation_date={installation_date}
                />
                <h3>{t("report_autotests_name")}</h3>
                <TestReport
                    tests={autotests}
                />
                <h3>{t("report_manual_tests_name")}</h3>
                <TestReport
                    tests={manual_tests}
                />
            </div>

            
            <div
                style = {{
                    position:'fixed',
                    bottom: '20px',
                    right: '0px',
                    left: '0px',
                    display: 'flex',
                    justifyContent: 'center',

                }}
            >
                <div
                    style= {{
                        background: 'white',
                        maxWidth: '850px',
                        minWidth: '850px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        padding: '20px',
                        borderRadius: '10px',
                    }}
                >
                    <div
                        style = {{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            width: '100%',
                        
                            alignItems: 'center',
                        }}
                    >
                    <Button 
                        view="outlined-action"
                        style={{ minWidth: '170px', justifySelf: 'flex-end' }}
                        size='l'
                        width="auto"
                        onClick={() => onClose()}
                    >
                        {t("device_print_label")}
                    </Button>
                    <Button
                        view="outlined-action"
                        style={{ minWidth: '170px', justifySelf: 'flex-end' }}
                        size='l'
                        width="auto"
                        onClick={() => onClose()}
                    >
                        {t("device_report_label")}
                    </Button>
                    </div>
                    <Button
                        view="action"
                        style={{ minWidth: '170px', justifySelf: 'flex-end' }}
                        size='l'
                        width="auto"
                        onClick={() => onClose()}
                    >
                        {t("device_close_registration")}
                    </Button>

                </div>
                
            </div>
        </div>
    )
}


const ReportCard = ({children}) => {
    return (
        <Card
            size='l'
            style={{
                width: '100%',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                backgroundColor: '#f8f8f8',
                border: 'none'
            }}
        >
            {children}
        </Card>
    )
}

const TestReport = ({tests}) => {
    const { t } = useTranslation("device_registration");
    return (
        <ReportCard>
            <Table
                data={tests}

                columns={[
                    { 
                        id: 'name', 
                        name: t("device_test_name"),
                        width: 550 
                    },
                    { 
                        id: 'component', 
                        name: t("device_test_component"),
                        width: 550 
                    },
                    { 
                        id: 'status', 
                        name: t("device_test_status"),
                        width: 150, 
                        template: (row) => {
                        return <Label theme={row.status === 'pass' ? 'success' : 'danger'}>
                                    {row.status==='pass'?t("device_test_pass"):t("device_test_fail")}
                               </Label>
                    } },
                ]}
            />
        </ReportCard>
    )
}


export default DeviceReport;