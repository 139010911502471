import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Select, Button } from '@gravity-ui/uikit';
import { MainPageCard } from 'components/MainPageView';

const SelectDeviceType = ({onInstall, onSelect, selected, options}) => {
    const { t } = useTranslation("device_registration");
    return (
        <MainPageCard>
            <div
                style = {{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
            <Select
                width={350}
                size='xl'
                value = {selected}
                onUpdate={(e) => {onSelect(e)}}
                placeholder={t("device_type_placeholder")}
            >
                 {options.map((option) => (
                     <Select.Option key={option.value} value={option.value}>
                        {option.content}
                     </Select.Option>
                ))}
            </Select>
            {selected.length > 0?
                <Button
                    view="action"
                    style={{ minWidth: '170px', justifySelf: 'flex-end' }}
                    size='l'
                    width="auto"
                    onClick={() => onInstall(true)}
                >
                    {t("device_install_button")}
                </Button> : null
            }
            </div>
        </MainPageCard>
    )
}

export default SelectDeviceType;