import React, {useEffect} from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';
import qCoreApi from 'api';
import serviceStore from 'stores/ServiceStore';
import {Table, withTableSelection, withTableActions} from '@gravity-ui/uikit';
import { useTranslation } from 'hooks/useTranslation';
import {Label} from '@gravity-ui/uikit';
import {DropdownMenu} from '@gravity-ui/uikit';

const MyTable =  (withTableSelection(Table));

function versionToArray(version) {
    return version.split('.').map(Number);
  }
  

function get_services(services) {
  const items = [];

  const res = services.slice().sort((a, b) => {
    if (a.name > b.name) return -1;
    if (a.name < b.name) return 1;

    const versionA = versionToArray(a.version);
    const versionB = versionToArray(b.version);

    for (let i = 0; i < versionA.length; i++) {
        if (versionA[i] > versionB[i]) return -1;
        if (versionA[i] < versionB[i]) return 1;
    }

    return 0;
  });
  
  
  let name = '';
  let used_devices = new Set();

  for (let i = 0; i < res.length; i++) {
    const targets = serviceStore.policy_targets[res[i].policy_id]?serviceStore.policy_targets[res[i].policy_id]:[]
    
    if (name !== res[i].name) {
      name = res[i].name;

      items.push({
        ...res[i],
        "targets" : targets?.length
      })
      used_devices = new Set(targets);
    } else {
      name = res[i].name;
      items.push({
        ...res[i],
        
        "targets" : targets?.filter((x) => !used_devices.has(x))?.length
      })
      used_devices = new Set([...used_devices, ...targets]);
    }
  }

  return items;
}

const ServicesPage = observer( () => {
    const auth = useAuth();

    const { t } = useTranslation("services");
    useEffect(() => {
        qCoreApi.get_services((data) => {
            serviceStore.setServices(data.services)
        }, (error) => {console.log(error)});
        qCoreApi.get_policies((data) => {
            serviceStore.setPolicies(data.policies)
        });
        qCoreApi.get_policies_targets((data) => {
            serviceStore.setPolicyTargets(data.policies_targets)
        });
        qCoreApi.get_service_deployment_status((data) => {
            serviceStore.setServiceDeploymentStatus(data.services_deployment_status)
        });
    }, [auth.isAuthenticated]);


    const [selectedIds, setSelectedIds] = React.useState([]);
  

    const services = get_services(serviceStore.services);


    return (
        <div>
            <h2> {t("services_title")}</h2>
            <MyTable 
                data={services.map((item) => {
                    return {
                        ...item,
                        'policy_name' : serviceStore.policies.find((policy) => policy.id === item.policy_id)?.name,
                        'installed' : serviceStore.service_deployment_status[item.id]?.status.deployed,
                    }
                }
                )}
                selectedIds={selectedIds}
                style = {{
                    minWidth : 600
                }}
                onSelectionChange={setSelectedIds}
                columns = {[
                    {
                        'id' : 'name',
                        "name" : t("services_table_name")
                    },
                    {
                        'id' : 'policy_name',
                        'name' : t("services_table_policy_name"),
                        'template' : (item) => {
                            return <DropdownMenu
                                renderSwitcher={(props) => (
                                    <div {...props} style={{cursor: 'pointer'}}>
                                        <Label theme="info">{item.policy_name}</Label>
                                    </div>
                                )}
                                items={
                                    serviceStore.policies.map((policy) => {
                                        return {
                                            'id' : policy.id,
                                            'text' : policy.name,
                                            'active' : item.policy_name===policy.name,
                                            'action' : () => {
                                                qCoreApi.apply_policy(item.id, policy.id, (data) => {
                                                    console.log(data);
                                                    serviceStore.setServicePolicy(item.id, policy.id);
                                                }
                                                );

                                            }
                                        }
                                    })
                                }
                              
                            />
                            
                        }
                    },
                    {
                        'id' : 'status',
                        'name' : t("services_table_status"),
                        'template' : (item) =>{
                            return <Label
                                theme = {item.status === 'created' ? 'info' : 'success'}
                            >{item.status}</Label>
                        }
                    },
                    {
                        'id' : 'version',
                        'name' : t("services_table_version")

                    },
                    {
                        'id' : 'installed',
                        'name' : t("services_table_installed"),
                        'template' : (item) => {
                            return <Label theme ={item.installed < item.targets ? 'warning' : 'success'}>{item.installed?item.installed:0}/{item.targets}</Label>
                        }
                    }
                   
                ]}
            />
        </div>
    );
})

export default ServicesPage;