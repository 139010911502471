import React, {useRef} from "react";

import { MainPageCard } from "components/MainPageView"
import { useTranslation } from 'hooks/useTranslation';
import {TextInput, Popup} from '@gravity-ui/uikit';
import {DatePicker, DateField, RangeCalendar} from '@gravity-ui/date-components';
import {dateTimeParse, dateTime} from '@gravity-ui/date-utils';

import './MainInfo.css';

const CampaignMainInfo = ({campaign, setCampaign}) => {
    const {t} = useTranslation("campaign_create");
    const [openLeft, setOpenLeft] = React.useState(false);
    const [openRight, setOpenRight] = React.useState(false);
    const [ref, setRef] = React.useState(null);
    const refStart = useRef(null);
    const refEnd = useRef(null);
    console.log(campaign);
    return (
        <MainPageCard>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
            <div>
                <TextInput 
                    value = {campaign.name}
                    className="campaign-name"
                    onUpdate={(value) => setCampaign({...campaign, name: value})}
                    size="xl"
                    style={{
                        fontSize: '100pt !important',
                    }}
                    placeholder={t('name_placeholder')}
                    view="clear"
                />      
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
            }}>
            <div>
                <TextInput 
                    value = {campaign.price}
                    className="campaign-price"
                    onUpdate={(value) => setCampaign({...campaign, price: value})}
                    view="clear"
                    size='xl'
                    label={t('price_left_content')}
                    placeholder={t('price_placeholder')}
                    
                />
            </div>
            <div
                style = {{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                }}
            >
                <div ref={refStart}>
                <DateField
                    value={dateTimeParse(campaign.startDate)}
                    readOnly={true}
                    placeholder={t('start_date_placeholder')}
                    onFocus={() => {setOpenLeft(true);}}
                    size="xl"
                    />
                </div>
                <div ref={refEnd}>
                <DateField
                    value={dateTimeParse(campaign.endDate)}
                    readOnly={true}
                    placeholder={t('end_date_placeholder')}
                    onFocus={() => {setOpenRight(true);}}
                    size="xl"
                    />
                </div>
                <Popup
                    open={openLeft||openRight}
                    onClose={() => {setOpenLeft(false); setOpenRight(false)}}
                    anchorRef={openLeft?refStart:openRight?refEnd:null}
                    position="bottom"
                    onOutsideClick={() => {console.log("close");setOpenLeft(false);setOpenRight(false)}}
                    >
                    <RangeCalendar
                        value={{
                            start: dateTimeParse(campaign.startDate), 
                            end : dateTimeParse(campaign.endDate)
                        }}
                        onUpdate={(value) => {
                            setOpenLeft(false);
                            setOpenRight(false);
                            setCampaign({...campaign, startDate: value.start, endDate: value.end});
                           
                        }}
                        />
                </Popup>
                

            </div>
            </div>
            </div>
        </MainPageCard>
    )
}

export default CampaignMainInfo