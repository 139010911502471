import { makeAutoObservable, action, observable } from 'mobx';

class DeviceStore {
    devices = [];
    device_deployment_status = {};
    constructor() {
        makeAutoObservable(this, {
            devices: observable,
            device_deployment_status: observable,
            setDevices: action,
            setDeviceDeploymentStatus: action,
        });
    }

    setDevices(devices) {
        console.log(devices);
        this.devices = devices;
    }

    setDeviceDeploymentStatus(device_deployment_status) {
        this.device_deployment_status = device_deployment_status;
    }

    addDevice(device) {
        console.log(device);
        this.devices.push(device);
    }
}

const deviceStore = new DeviceStore();

export default deviceStore;