import { makeAutoObservable, action, observable } from 'mobx';

class ServiceStore {
   services = [];
   policies = [];
   policy_targets = {};
   service_deployment_status = {};
    constructor() {
        makeAutoObservable(this, {
            services: observable,
            policies: observable,
            policy_targets: observable,
            service_deployment_status: observable,
            setServices: action,
            setPolicies: action,
            setPolicyTargets: action,
            setServiceDeploymentStatus: action,
            setServicePolicy: action
        });
    }

    setServices(services) {
        this.services = services;
    }

    setPolicies(policies) {
        this.policies = policies;
    }

    setPolicyTargets(policy_targets) {
        this.policy_targets = policy_targets;
    }

    setServiceDeploymentStatus(service_deployment_status) {
        this.service_deployment_status = service_deployment_status;
    }

    setServicePolicy(service_id, policy_id) {
        console.log(service_id, policy_id);
        const service = this.services.find((x) => x.id === service_id);
        console.log(service);
        service.policy_id = policy_id;
    }
}

const serviceStore = new ServiceStore();
export default serviceStore;