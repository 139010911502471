function formatDateToMinutes(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

function formatDateToMinutesFromString(date) {
    const dateObj = new Date(date);
    return formatDateToMinutes(dateObj);
}

export { formatDateToMinutes, formatDateToMinutesFromString };