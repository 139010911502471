import React from "react"
import { Card, Button } from "@gravity-ui/uikit"

const DownPageOverlay = ({ children}) => {
    return (
        <><div
            style={{
                marginBottom: '100px',
            }}
        >
        </div>
        <div style={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            left: '0',
            height: 'auto',
            display: 'flex',
        }}>
             <div
                    style = {{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        backgroundColor: 'white',
                        marginLeft: '70px',
                        marginRight: '78px',
                        paddingBottom: '20px',
                        width: '100%',
                    }}
                >
                    <Card
                        style={{
                            width: '100%',
                            padding : '20px',
                            display: 'flex',
                            flexDirection: 'row',
                           
                        }}
                    >
            {children}
            </Card>
            </div>
        </div>
        </>
    )
}


const DownPageRightContent = ({children}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            gap: '10px',
        }}>
            {children}
        </div>
    )
}

const DownPageTwoSidesContent = ({children}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            gap: '10px',
        }}>
            {children}
        </div>
    )
}

export default DownPageOverlay
export { DownPageRightContent, DownPageTwoSidesContent }
