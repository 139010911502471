import './index.css'
import React from 'react'
import {Copy} from '@gravity-ui/icons';
import {Icon, Button} from '@gravity-ui/uikit';

const InfoField = ({ label, value }) => {
    const copyToClipboard = () => {
      console.log(value)
      navigator.clipboard.writeText(value);
    };
  
    return (
      <div className="info-field-container">
        <div>
          <label className="field-label">{label}</label>
          <div className="info-field">
            <span className="field-value">{value}</span>
            

          </div>
        </div>
        <Button onClick={copyToClipboard} view="flat" ><Icon data ={Copy}  size={20}/></Button>
      </div>
    );
  };
  
  export default InfoField;
